import { getMobileOSType } from "Common/Utils/CheckDevice";
import {
    getMobileAuthCookie,
    getPlatformNamefromCookie,
} from "./getMobileCookies";

export const handleMobileToSetUserAuthToken = (
    userAuthResponse = {},
    isReload?: boolean
) => {
    const mobileAuthCookie = getMobileAuthCookie();
    const platFormName =
        getPlatformNamefromCookie(mobileAuthCookie) || getMobileOSType();
    (window as any).onConverseReload = () => {
        window.location.reload();
    };

    if (platFormName === "android") {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (window as any)?.LSQAndroidClient?.onNewConverseUserAuthResponse(
            JSON.stringify({
                converseUserAuthResponse: userAuthResponse,
            })
        );
        if (isReload) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            (window as any)?.LSQAndroidClient?.onViewReload(
                JSON.stringify({
                    callback: "onConverseReload",
                })
            );
        }
    } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (
            window as any
        )?.webkit?.messageHandlers?.onNewConverseUserAuthResponse?.postMessage(
            JSON.stringify({
                converseUserAuthResponse: userAuthResponse,
            })
        );
        if (isReload) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            (window as any)?.webkit?.messageHandlers?.onViewReload?.postMessage(
                JSON.stringify({
                    callback: "onConverseReload",
                })
            );
        }
    }
};

export const handleMobileExceptions = (exceptionDetails: {
    ExceptionType: string;
}) => {
    // (e as any).response?.data?.exceptionDetails || {};
    const { ExceptionType: exceptionType = "" } = exceptionDetails || {};
    const mobileAuthCookie = getMobileAuthCookie();
    const platFormName =
        getPlatformNamefromCookie(mobileAuthCookie) || getMobileOSType();
    const { STATICAUTH = "", DYNAMICAUTH = "" } = mobileAuthCookie;
    const isAuthKeyEmpty = STATICAUTH === "" && DYNAMICAUTH === "";
    (window as any).onConverseTokenRefresh = () => {
        window.location.reload();
    };
    if (platFormName === "android") {
        if (
            exceptionType === "MXMobileTokenMismatchException" ||
            exceptionType === "MXMobileInvalidSessionException" ||
            isAuthKeyEmpty
        ) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            (window as any).LSQAndroidClient?.onSessionExpired(
                JSON.stringify({
                    exception: isAuthKeyEmpty
                        ? "MXMobileTokenMismatchException"
                        : exceptionType,
                    callback: "onConverseTokenRefresh",
                    isAuthKeyEmpty,
                })
            );
        } else if (exceptionType === "MXMobileTokenExpiredException") {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            (window as any).LSQAndroidClient?.onRefreshToken(
                JSON.stringify({
                    exception: exceptionType,
                    callback: "onConverseTokenRefresh",
                })
            );
        }
    } else if (platFormName === "ios") {
        if (
            exceptionType === "MXMobileTokenMismatchException" ||
            exceptionType === "MXMobileInvalidSessionException" ||
            isAuthKeyEmpty
        ) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            (
                window as any
            ).webkit?.messageHandlers?.onSessionExpired?.postMessage(
                JSON.stringify({
                    exception: isAuthKeyEmpty
                        ? "MXMobileTokenMismatchException"
                        : exceptionType,
                    callback: "onConverseTokenRefresh",
                    isAuthKeyEmpty,
                })
            );
        } else if (exceptionType === "MXMobileTokenExpiredException") {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            (
                window as any
            ).webkit?.messageHandlers?.onRefreshToken?.postMessage(
                JSON.stringify({
                    exception: exceptionType,
                    callback: "onConverseTokenRefresh",
                })
            );
        }
    } else if (isAuthKeyEmpty) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (window as any).LSQAndroidClient?.onSessionExpired(
            JSON.stringify({
                exception: "MXMobileTokenMismatchException",
                callback: "onConverseTokenRefresh",
                isAuthKeyEmpty,
            })
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (window as any).webkit?.messageHandlers?.onSessionExpired?.postMessage(
            JSON.stringify({
                exception: "MXMobileTokenMismatchException",
                callback: "onConverseTokenRefresh",
                isAuthKeyEmpty,
            })
        );
    }
};

export const handleMobileOnCurrentLeadChatOpen = (
    modifiedOpportunityInstanceId: string,
    platform: string
) => {
    const platFormName = platform.toLowerCase();
    const opportunityInstanceIdObject = JSON.stringify({
        opportunityInstanceId: modifiedOpportunityInstanceId,
    });

    if (platFormName === "android") {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (window as any).LSQAndroidClient?.onCurrentLeadChatOpen(
            opportunityInstanceIdObject
        );
    } else if (platFormName === "ios") {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (
            window as any
        ).webkit?.messageHandlers?.onCurrentLeadChatOpen?.postMessage(
            opportunityInstanceIdObject
        );
    }
};
